import ReactDOM from "react-dom/client";
// import { Auth0Provider } from "@auth0/auth0-react";
import "normalize.css";
import { ThemeDefaults } from "./theme";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// root.render(
//   <Auth0Provider
//     domain="dev-nwf4w2pl3v6pvsoj.us.auth0.com"
//     clientId="SzA0LwACmPbz4cHm4t8LaC2r27tmxtyW"
//     authorizationParams={{
//       redirect_uri: window.location.origin,
//     }}
//     cacheLocation={"localstorage"}
//   >
//     <ThemeDefaults />
//     <App />
//   </Auth0Provider>
// );
root.render(
  <>
    <ThemeDefaults />
    <App />
  </>
);
